import { makeStyles } from "@mui/styles";

let visaApplyingStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
    display: "flex",
    width: "100%",
    height: "inherit",
  },
  contentHead: {
    "& h3": {
      display: "flex",
      gap: 10,
      alignItems: "center",
    },
  },
  actionBtn: {
    display: "flex",
    gap: 4,
    flexDirection: "row-reverse",
  },
  paper: {
    padding: 20,
    margin: "10px 0px",
    border: `1px solid ${theme.palette.grey[400]}`,
    "& .searchField": {
      marginBottom: 20,
    },
  },
  divider: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  contentMain: {
    height: "fit-content",
    overflow: "auto",
    padding: 10,
  },
  contentSection: {
    height: "fit-content",
    overflow: "none",
    [theme.breakpoints.up("xs")]: {
      padding: "80px 20px",
      width: "100%",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "80px 40px",
      marginBottom: 70,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "80px 20px",
    },
  },
}));

export default visaApplyingStyles;
