import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom right, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  "500_8": alpha("#919EAB", 0.08),
  "500_12": alpha("#919EAB", 0.12),
  "500_16": alpha("#919EAB", 0.16),
  "500_24": alpha("#919EAB", 0.24),
  "500_32": alpha("#919EAB", 0.32),
  "500_48": alpha("#919EAB", 0.48),
  "500_56": alpha("#919EAB", 0.56),
  "500_80": alpha("#919EAB", 0.8),
};

const PRIMARY = {
  lighter: "#d8d9dc",
  light: "#d8d9dc",
  main: "#27282C",
  //prev #003F90
  dark: "#151517",
  darker: "#151517",
  //darker: "#001F7B",
  contrastText: "#fff",
};
const BOX1 = {
  lighter: "#fce4ad",
  light: "#fbda91",
  main: "#ffdd94",
  //prev #003F90
  dark: "#bf8710",
  darker: "#bf8710",
  //darker: "#001F7B",
  contrastText: "#fff",
};
const BOX2 = {
  lighter: "#abedca",
  light: "#9dedc2",
  main: "#89f5ba",
  dark: "#09ab2d",
  darker: "#3bb070",
  contrastText: "#fff",
};
const BOX3 = {
  lighter: "#cfbafc",
  light: "#bfa6f6",
  main: "#b395f6",
  dark: "#451ca0",
  darker: "#533f80",
  contrastText: "#fff",
};
const BOX4 = {
  lighter: "#eda4b1",
  light: "#ed8d9e",
  main: "#ed8093",
  dark: "#cd1a39",
  darker: "#a4142d",
  contrastText: "#fff",
};
const BOX5 = {
  lighter: "#95def4",
  light: "#88d9f1",
  main: "#5cd2f5",
  dark: "#037da1",
  darker: "#077494",
  contrastText: "#fff",
};

const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A",
  contrastText: "#fff",
};
const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
};
const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: GREY[800],
};
const WARNING = {
  lighter: "#ffe3ba",
  light: "#FFAD8B",
  main: "#FAA35B",
  dark: "#CB7400",
  darker: "#9F5000",
  contrastText: GREY[800],
};
const ERROR = {
  lighter: "#FFDBDE",
  light: "#FFA48D",
  //main: "#FF505F",
  main: "#f97316",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  box1: createGradient(BOX1.light, BOX1.main),
  box2: createGradient(BOX2.light, BOX2.main),
  box3: createGradient(BOX3.light, BOX3.main),
  box4: createGradient(BOX4.light, BOX4.main),
  box5: createGradient(BOX5.light, BOX5.main),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
};

const palette = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  box1: { ...BOX1 },
  box2: { ...BOX2 },
  box3: { ...BOX3 },
  box4: { ...BOX4 },
  box5: { ...BOX5 },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY["500_24"],
  text: { primary: GREY[700], secondary: GREY[800], disabled: GREY[500] },
  background: { paper: "#fff", default: "#fff", neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY["500_8"],
    selected: GREY["500_16"],
    disabled: GREY["500_80"],
    disabledBackground: GREY["500_24"],
    focus: GREY["500_24"],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
    selectedOpacity: 1,
  },
};

export default palette;
