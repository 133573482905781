import { CircularProgress } from "@mui/material";
import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import "./App.css";
import DashBoard from "./pages/Dashboard/Index";
import Login from "./pages/Login/Login";
import useStyles from "./styles/main.styles";
import GlobalStyles from "./theme/globalStyles";
import ThemeConfig from "./theme/index";
import Coupon from "./pages/Dashboard/coupon";
import PayoutPage from "./pages/Dashboard/payout";
import VisaTimePage from "./pages/VisaTime/Index";
import CountryPage from "./pages/Country/Index";
import CountryList2Page from "./pages/CountryList_2/Index";
import InformationPage from "./pages/Information_form/Index";
import InfoListPage from "./pages/Information_list/Index";
import EntryPage from "./pages/Entry/Index";
import Immigration from "./pages/Immigration_form";
import ImmigrationList from "./pages/Immigration_list/Index";
import Partener_toWork from "./pages/Partner_to_work/Index";
import FAQ_for_visa from "./pages/FAQ_for_visa/Index";
import Testimonial from "./pages/Testimonial";
import Testimonial_list from "./pages/Testimonial_list/Index";
import Discount_type from "./pages/Discount_type/Index";
import QuestionPage from "./pages/Question/Index";
import Visa_for_country from "./pages/For_which_country/Index";
import Payment_gateway from "./pages/Payment_gateway/Index";
import FAQ_for_country from "./pages/FAQ_for_country";
import VisaApplicationStatus from "./pages/Visa_application_status/Index";
import Docs_required from "./pages/Docs_required/Index";
import Question_list from "./pages/Question_list/Index";
import FAQ from "./pages/FAQ/Index";
import Fee_type from "./pages/Fee_type/Index";
import Visa_fee from "./pages/Visa_Fee/Index";
import News_letter from "./pages/News_letter/Index";
import VisaComparePricing from "./pages/VisaComparePricing/Index";
import Application_list from "./pages/Application_list/Index";
import CouponAdd from "./pages/Coupon/Index";
import Seo from "./pages/SEO/Index";
import Application_details from "./pages/Application_details/Index";
import BlogSection from "./pages/BlogSection/Index";
import BlogCategory from "./pages/Blog_category/Index";
import Bloglist from "./pages/Blog_list/Index";
import CoverDetails from "./pages/Cover_details/Index";
import Customer_testimonial from "./pages/Customer_testimonial/Index";
import Customer_testimonialList from "./pages/Customer_testimonialList/Index";
import Customize_page from "./pages/Customize_page/Index";
import Settings from "./pages/Settings/Index";
import User_Contact_List from "./pages/User_Contact_List/Index";
import BeneficiaryList from "./pages/Beneficiary_list/Index";
import Rejected_application from "./pages/Rejected_app/Index";
import Pending_application from "./pages/Pending_app/Index";

const App = () => {
  const isExist = localStorage.getItem("tkn");
  const classes = useStyles();
  const [isAuth, setIsAuth] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    setLoading(false);
  }, [isExist]);
  let routes = useRoutes([
    {
      path: "/",
      element: !isAuth ? <Login /> : <Navigate to={"/dashboard"} />,
    },
    {
      path: "/dashboard",
      element: !isAuth ? <DashBoard /> : <Navigate to={"/"} />,
    },
    {
      path: "/country",
      element: !isAuth ? <CountryPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/information_form",
      element: !isAuth ? <InformationPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/country_list2",
      element: !isAuth ? <CountryList2Page /> : <Navigate to={"/"} />,
    },
    {
      path: "/information_list",
      element: !isAuth ? <InfoListPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/entry",
      element: !isAuth ? <EntryPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/immigration_form",
      element: !isAuth ? <Immigration /> : <Navigate to={"/"} />,
    },

    {
      path: "/immigration_list",
      element: !isAuth ? <ImmigrationList /> : <Navigate to={"/"} />,
    },
    {
      path: "/visa_time",
      element: !isAuth ? <VisaTimePage /> : <Navigate to={"/"} />,
    },
    {
      path: "/partners",
      element: !isAuth ? <Partener_toWork /> : <Navigate to={"/"} />,
    },
    {
      path: "/faq_visa",
      element: !isAuth ? <FAQ_for_visa /> : <Navigate to={"/"} />,
    },
    {
      path: "/testimonial",
      element: !isAuth ? <Testimonial /> : <Navigate to={"/"} />,
    },
    {
      path: "/testimonial_list",
      element: !isAuth ? <Testimonial_list /> : <Navigate to={"/"} />,
    },
    {
      path: "/discount",
      element: !isAuth ? <Discount_type /> : <Navigate to={"/"} />,
    },
    {
      path: "/question",
      element: !isAuth ? <QuestionPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/for_which_country",
      element: !isAuth ? <Visa_for_country /> : <Navigate to={"/"} />,
    },
    {
      path: "/payment_gateway",
      element: !isAuth ? <Payment_gateway /> : <Navigate to={"/"} />,
    },
    {
      path: "/faq_for_country",
      element: !isAuth ? <FAQ_for_country /> : <Navigate to={"/"} />,
    },
    {
      path: "/application_status",
      element: !isAuth ? <VisaApplicationStatus /> : <Navigate to={"/"} />,
    },
    {
      path: "/docs_required",
      element: !isAuth ? <Docs_required /> : <Navigate to={"/"} />,
    },
    {
      path: "/question_list",
      element: !isAuth ? <Question_list /> : <Navigate to={"/"} />,
    },
    {
      path: "/faq",
      element: !isAuth ? <FAQ /> : <Navigate to={"/"} />,
    },
    {
      path: "/fee_type",
      element: !isAuth ? <Fee_type /> : <Navigate to={"/"} />,
    },
    {
      path: "/Visa_fee",
      element: !isAuth ? <Visa_fee /> : <Navigate to={"/"} />,
    },
    {
      path: "/coupon",
      element: !isAuth ? <CouponAdd /> : <Navigate to={"/"} />,
    },
    {
      path: "/pricing_cmp",
      element: !isAuth ? <VisaComparePricing /> : <Navigate to={"/"} />,
    },
    {
      path: "/news_letter",
      element: !isAuth ? <News_letter /> : <Navigate to={"/"} />,
    },
    {
      path: "/seo",
      element: !isAuth ? <Seo /> : <Navigate to={"/"} />,
    },
    {
      path: "/application_list",
      element: !isAuth ? <Application_list /> : <Navigate to={"/"} />,
    },
    {
      path: "/blog",
      element: !isAuth ? <BlogSection /> : <Navigate to={"/"} />,
    },
    {
      path: "/blog_category",
      element: !isAuth ? <BlogCategory /> : <Navigate to={"/"} />,
    },
    {
      path: "/bloglist",
      element: !isAuth ? <Bloglist /> : <Navigate to={"/"} />,
    },
    {
      path: "/cover_letter_list",
      element: !isAuth ? <CoverDetails /> : <Navigate to={"/"} />,
    },
    {
      path: "/application_details",
      element: !isAuth ? <Application_details /> : <Navigate to={"/"} />,
    },

    {
      path: "/dashboard/coupons",
      element: !isAuth ? <Coupon /> : <Navigate to={"/"} />,
    },
    {
      path: "/dashboard/payouts",
      element: !isAuth ? <PayoutPage /> : <Navigate to={"/"} />,
    },
    {
      path: "/customer_testimonial",
      element: !isAuth ? <Customer_testimonial /> : <Navigate to={"/"} />,
    },
    {
      path: "/customer_testimoniallist",
      element: !isAuth ? <Customer_testimonialList /> : <Navigate to={"/"} />,
    },
    {
      path: "/customize_page",
      element: !isAuth ? <Customize_page /> : <Navigate to={"/"} />,
    },
    {
      path: "/setting",
      element: !isAuth ? <Settings /> : <Navigate to={"/"} />,
    },
    {
      path: "/contact_list",
      element: !isAuth ? <User_Contact_List /> : <Navigate to={"/"} />,
    },
    {
      path: "/beneficiary_list",
      element: !isAuth ? <BeneficiaryList /> : <Navigate to={"/"} />,
    },
    {
      path: "/pending_list",
      element: !isAuth ? <Pending_application /> : <Navigate to={"/"} />,
    },
    {
      path: "/rejected_list",
      element: !isAuth ? <Rejected_application /> : <Navigate to={"/"} />,
    },
    { path: "*", element: <Navigate to={"/404"} /> },
  ]);
  if (loading)
    return (
      <div className={classes.loader}>
        <CircularProgress
          disableShrink
          size={35}
          color="primary"
          thickness={6}
        />
      </div>
    );
  return <div className={classes.headContainer}>{routes}</div>;
};

const AppWrapper = () => {
  return (
    <div className="App">
      {/* <CssBaseline /> */}
      <ThemeConfig>
        <GlobalStyles />
        <App />
      </ThemeConfig>
    </div>
  );
};

export default AppWrapper;
