import { Avatar, Divider, Typography, Grid, Button } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { BsListCheck } from "react-icons/bs";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import endPoints from "../../config/endPoint";
import axios from "axios";
import { useNavigate } from "react-router";
import CountryTable from "../../components/CountryTable/Index";
import { EditorState, convertFromRaw } from "draft-js";
import { MdLogout } from "react-icons/md";
export default function CoverDetails() {
  const classes = countryListStyles();
  const [list, setList] = React.useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const [searchText, setSearchText] = React.useState("");
  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        "http://13.232.52.101/api/coverletterdetails/getallcoverletterdetails",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setList(actualData.CoverLetterDetails);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    table();
  }, []);
  // const handleDeleteCountry = async (idx) => {
  //   try {
  //     const loginHeaders = new Headers();
  //     loginHeaders.append("Content-Type", "application/json");

  //     // Assuming you have an authorization token stored in localStorage
  //     const authToken = localStorage.getItem("tkn");
  //     if (authToken) {
  //       loginHeaders.append("Authorization", `Bearer ${authToken}`);
  //     }
  //     const data = { id: idx };
  //     const requestOptions = {
  //       method: "POST",
  //       headers: loginHeaders,
  //       body: JSON.stringify(data),
  //     };
  //     const res = await fetch(
  //       "http://13.232.52.101/api/blog/deleteblog",
  //       requestOptions
  //     );
  //     const actualData = await res.json();
  //     console.log(actualData);
  //     // setVisaList(actualData.Country);
  //     if (actualData.status == 200) {
  //       table();
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              Cover Letter List
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>

        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "Name",
              "Passport Number",
              "Occupation",
              "Destination Country",
              "Departure Date",
              "Return Date",
              "Purpose",
            ]}
            rows={[
              ...list
                .sort()
                .filter((l) => {
                  const fullName = l.full_name?.toLowerCase() || "";
                  const occupation = l.occupation?.toLowerCase() || "";
                  const destination_country =
                    l.destination_country?.toLowerCase() || "";
                  const departure_date = l.departure_date?.toLowerCase() || "";
                  const return_date = l.return_date?.toLowerCase() || "";
                  // const destination_country =
                  //   l.destination_country?.toLowerCase() || "";
                  const purpose = l.purpose?.toLowerCase() || "";
                  const searchLower = searchText.toLowerCase();
                  const passport_number =
                    (l.passport_number ?? "").toString().toLowerCase() || "";
                  return (
                    fullName.includes(searchLower) ||
                    occupation.includes(searchLower) ||
                    destination_country.includes(searchLower) ||
                    departure_date.includes(searchLower) ||
                    return_date.includes(searchLower) ||
                    purpose.includes(searchLower) ||
                    passport_number.includes(searchLower) ||
                    searchLower === undefined
                  );
                })
                // .filter(
                //   (l) =>
                //     l.full_name
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     String(l.passport_number).includes(searchText) ||
                //     l.occupation
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     l.destination_country
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     l.departure_date.includes(searchText) ||
                //     l.return_date.includes(searchText) ||
                //     l.purpose
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     searchText === undefined
                // )
                .map((vs, idx) => [
                  idx + 1,
                  vs.full_name,
                  vs.passport_number,
                  vs.occupation,
                  vs.destination_country,
                  vs.departure_date,
                  vs.return_date,
                  vs.purpose,
                ]),
            ]}
          />
        </div>
      </div>
    </div>
  );
}
