import React, { useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { FaEllipsisV } from "react-icons/fa";

const CustomMenuButton = ({ menuinfo }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [updateStatusOpen, setUpdateStatusOpen] = useState(false);
  const [status, setStatus] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleView = () => {
    setViewOpen(true);
    handleClose();
  };

  const handleUpdateStatusClick = () => {
    setUpdateStatusOpen(true);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setViewOpen(false);
    setUpdateStatusOpen(false);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleUpdateStatus = () => {
    // Handle the status update logic here
    console.log("Status updated to:", status);
    setUpdateStatusOpen(false);
  };

  return (
    <>
      <FaEllipsisV
        size={20}
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleView}>View</MenuItem>
        <MenuItem onClick={handleUpdateStatusClick}>Update Status</MenuItem>
      </Menu>

      <Dialog open={viewOpen} onClose={handleDialogClose}>
        <DialogTitle>Applicant Information</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              <strong>Applicant Name:</strong> {menuinfo.applicantName}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Village Name:</strong> {menuinfo.villageName}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Block Name:</strong> {menuinfo.blockName}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>District:</strong> {menuinfo.district}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Date:</strong> {menuinfo.date}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Status:</strong> {menuinfo.status}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={updateStatusOpen} onClose={handleDialogClose}>
        <DialogTitle>Update Status</DialogTitle>
        <DialogContent>
          <Box sx={{ p: 2 }}>
            <TextField
              autoFocus
              margin="dense"
              id="status"
              label="Status"
              type="text"
              fullWidth
              value={status}
              onChange={handleStatusChange}
              variant="standard"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateStatus} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomMenuButton;
