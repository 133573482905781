import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CountryTable from "../../components/CountryTable/Index";
import slugify from "react-slugify";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { MdLogout } from "react-icons/md";

export default function Settings() {
  const navigate = useNavigate();
  const classes = offerStyles();
  const formData = new FormData();
  const location = useLocation();
  const { updateId } = location.state || {};
  const [state, setState] = React.useState({
    privacy_description: EditorState.createEmpty(),
    terms_and_condition: EditorState.createEmpty(),
    email: "",
    phone: null,
    address: "",
    isUpdate: false,
  });
  const [visaList, setVisaList] = React.useState([]);
  React.useEffect(() => {
    // table();
    if (updateId) {
      const updateobj = updateId;
      // console.log(updateobj.id);
      setState({
        country_name: updateobj.country_name,
        country_banner: updateobj.country_banner,
        country_flag: updateobj.country_flag,
        country_description: EditorState.createWithContent(
          convertFromRaw(JSON.parse(updateobj.country_description))
        ),
        currency: updateobj.currency,
        currency_code: updateobj.currency_code,
        isUpdate: true,
        visaId: updateobj.id,
      });
      // localStorage.removeItem("updateId");
      // handleUpdateVisaCategory(updateobj.id);
    }
  }, []);

  const handleSaveVisaCategory = async () => {
    if (
      state.country_name == "" ||
      state.country_description == "" ||
      state.currency_code == "" ||
      state.country_banner == "" ||
      state.country_flag == "" ||
      state.currency == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      try {
        const loginHeaders = new Headers();
        loginHeaders.append("Content-Type", "application/json");

        // Assuming you have an authorization token stored in localStorage
        const authToken = localStorage.getItem("tkn");
        if (authToken) {
          loginHeaders.append("Authorization", `Bearer ${authToken}`);
        }
        const data = {
          country_name: state.country_name,
          country_banner: state.country_banner,
          country_flag: state.country_flag,
          country_description: JSON.stringify(
            convertToRaw(state.country_description.getCurrentContent())
          ),
          currency: state.currency,
          currency_code: state.currency_code,
        };
        const requestOptions = {
          method: "POST",
          headers: loginHeaders,
          body: JSON.stringify(data),
        };
        const res = await fetch(
          "http://13.232.52.101/api/country/createcountry",
          requestOptions
        );
        const actualData = await res.json();
        console.log(actualData);
        // setVisaList(actualData.Country);
        if (actualData.status == 200) {
          // table();
          setState({
            country_name: "",
            country_banner: "",
            country_flag: "",
            country_description: "",
            currency: "",
            currency_code: "",
            isUpdate: false,
          });

          navigate("/country_list2");
        } else {
          toast.error(actualData.message, {
            position: "top-center",
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleUpdateVisaCategory = async (id) => {
    if (
      state.country_name == "" ||
      state.country_description == "" ||
      state.currency_code == "" ||
      state.country_banner == "" ||
      state.country_flag == "" ||
      state.currency == ""
    ) {
      toast.error("Fill all the information", {
        position: "top-center",
      });
    } else {
      try {
        const loginHeaders = new Headers();
        loginHeaders.append("Content-Type", "application/json");

        // Assuming you have an authorization token stored in localStorage
        const authToken = localStorage.getItem("tkn");
        if (authToken) {
          loginHeaders.append("Authorization", `Bearer ${authToken}`);
        }
        const data = {
          country_name: state.country_name,
          country_banner: state.country_banner,
          country_flag: state.country_flag,
          country_description: JSON.stringify(
            convertToRaw(state.country_description.getCurrentContent())
          ),
          currency: state.currency,
          currency_code: state.currency_code,
          country_slug: slugify(state.country_name),
          id: id,
        };
        const requestOptions = {
          method: "POST",
          headers: loginHeaders,
          body: JSON.stringify(data),
        };
        const res = await fetch(
          "http://13.232.52.101/api/country/updatecountry",
          requestOptions
        );
        const actualData = await res.json();
        console.log(actualData);
        if (actualData.status == 200) {
          localStorage.removeItem("updateId");
          setState({
            country_name: "",
            country_banner: "",
            country_flag: "",
            country_description: "",
            currency: "",
            currency_code: "",
            isUpdate: false,
          });

          navigate("/country_list2");
        } else {
          toast.error(actualData.message, {
            position: "top-center",
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              Setting
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <div className={classes.contentMain} style={{ width: "100%" }}>
            <p>Privacy Policy</p>
            <Paper className={classes.paper} style={{ marginLeft: "16px" }}>
              <Grid
                container
                spacing={3}
                className="content"
                alignItems={"end"}
              >
                <Grid item xs={12}>
                  <Editor
                    label="Description"
                    editorState={state.privacy_description}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(e) =>
                      setState({
                        ...state,
                        privacy_description: e,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </div>{" "}
          <div className={classes.contentMain} style={{ width: "100%" }}>
            <p>Terms And Condition</p>
            <Paper className={classes.paper} style={{ marginLeft: "16px" }}>
              <Grid
                container
                spacing={3}
                className="content"
                alignItems={"end"}
              >
                <Grid item xs={12}>
                  <Editor
                    label="Description"
                    editorState={state.terms_and_condition}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(e) =>
                      setState({
                        ...state,
                        terms_and_condition: e,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </div>{" "}
          <div className={classes.contentMain} style={{ width: "100%" }}>
            <p>Contact Information</p>
            <Paper className={classes.paper} style={{ marginLeft: "16px" }}>
              <Grid
                container
                spacing={3}
                className="content"
                alignItems={"end"}
              >
                <Grid item xs={4}>
                  <CustomInputField
                    label="Phone"
                    type="number"
                    fullWidth
                    value={state.phone}
                    onChange={(e) =>
                      setState({
                        ...state,
                        phone: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInputField
                    label="Email"
                    fullWidth
                    value={state.email}
                    onChange={(e) =>
                      setState({
                        ...state,
                        email: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomInputField
                    label="Address"
                    fullWidth
                    value={state.address}
                    onChange={(e) =>
                      setState({
                        ...state,
                        address: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
            {/* <Grid container spacing={4} className={classes.contentMain}>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={(e) => {
                    // navigate("/information_form");
                  }}
                >
                  Create
                </Button>
              </Grid>
            </Grid> */}
          </div>{" "}
          <Grid container spacing={4} className={classes.contentMain}>
            <Grid item xs={9}></Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                fullWidth
                onClick={(e) => {
                  // navigate("/information_form");
                }}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
