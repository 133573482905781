import { Divider, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { FaEdit, FaTimes } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomDatePicker from "../../components/CustomDateField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import couponStyles from "../../styles/pages/Dashboard/coupon.style";

export default function VisaCategoryPage() {
  const classes = couponStyles();
  const token = localStorage.getItem("token");
  const [state, setState] = React.useState({
    coupon: "",
    expiryDate: null,
    maxAmount: "",
    waivedAmount: "",
    couponType: "",
    isUpdate: false,
    searchText: "",
  });
  const [couponList, setCouponList] = React.useState([]);
  React.useEffect(() => {
    axios
      .get(endPoints.getAllCoupons)
      .then((r) => setCouponList(r.data.results));
  }, []);

  const handleSaveVisaCategory = () => {
    axios.post(endPoints.createCoupon, { ...state, token }).then((r) => {
      setCouponList([...couponList, r.data.results]);
    });
  };
  const handleVisaCategoryDelete = (idx) => {
    axios
      .delete(endPoints.deleteCoupon, {
        data: {
          token,
          couponId: idx,
        },
      })
      .then((r) => {
        setCouponList(couponList.sort().filter((ls, id) => ls._id !== idx));
      });
  };
  const handleUpdateVisaCategory = (id) => {
    axios
      .patch(endPoints.updateCoupon, {
        ...state,
        token,
        couponId: id,
      })
      .then((r) => {
        setCouponList(
          couponList
            .map((ls) => {
              if (ls._id === id) {
                return {
                  ...ls,
                  ...state,
                };
              } else {
                return ls;
              }
            })
            .sort()
        );
      });
    setState({
      coupon: "",
      expiryDate: null,
      maxAmount: "",
      waivedAmount: "",
      couponType: "",
      isUpdate: false,
      searchText: "",
    });
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div className={classes.contentHead}>
          <Typography variant="h3" color={"primary"}>
            Coupon
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <p className={classes.title}>Add New Coupon</p>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={6}>
                <CustomInputField
                  label="Coupon Name"
                  fullWidth
                  value={state.coupon}
                  onChange={(e) =>
                    setState({
                      ...state,
                      coupon: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInputAndSelectField
                  label="Coupon Type"
                  fullWidth
                  value={state.couponType}
                  options={["PERCENTAGE", "AMOUNT"]}
                  changeCallBack={(e, v) =>
                    setState({
                      ...state,
                      couponType: v,
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInputField
                  label="Amount/Percentage"
                  fullWidth
                  value={state.waivedAmount}
                  onChange={(e) =>
                    setState({
                      ...state,
                      waivedAmount: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInputField
                  label="Max Amount will be discounted"
                  fullWidth
                  value={state.maxAmount}
                  onChange={(e) =>
                    setState({
                      ...state,
                      maxAmount: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <CustomDatePicker
                  label="Expiry Date"
                  fullWidth
                  minDate={new Date()}
                  views={["year", "month", "day"]}
                  inputFormat="dd/MM/yyyy"
                  value={state.expiryDate}
                  changeCallBack={(e, v) =>
                    setState({
                      ...state,
                      expiryDate: v,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      if (!!state.coupon) {
                        handleUpdateVisaCategory(state.couponId);
                      }
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      if (!!state.coupon) {
                        handleSaveVisaCategory();
                        setCouponList([...couponList, state]);
                      }
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
          <p className={classes.title}>Coupon Lists</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTable
              fullWidth
              cols={[
                "Sl No.",
                "Coupon Name",
                "Amount/Percentage",
                "Coupon Type",
                "Action",
              ]}
              rows={[
                ...couponList
                  .sort()
                  .filter(
                    (l) =>
                      l.coupon.includes(state.searchText) ||
                      state.searchText === undefined
                  )
                  .map((vs, idx) => [
                    idx + 1,
                    vs.coupon,
                    vs.waivedAmount,
                    vs.couponType,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleVisaCategoryDelete(vs._id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) =>
                          setState({
                            ...vs,
                            isUpdate: true,
                            couponId: vs._id,
                          })
                        }
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
