import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
export default function FAQ_for_country() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const formData = new FormData();
  const [state, setState] = React.useState({
    faq_question: "",
    faq_id: "",
    from_country_name: "",
    from_country_id: "",
    to_country_name: "",
    to_country_id: "",
    searchText: "",
    isUpdate: false,
  });
  const [visaList, setVisaList] = React.useState([]);
  const [mapList, setMapList] = React.useState([]);
  const [countrylist, setcountrylist] = React.useState([]);
  const [mainlist, setMainList] = React.useState([]);
  async function table() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        "http://13.232.52.101/api/faqforvisa/getallfaqforvisa",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setVisaList(actualData.FAQ_For_Visa);
    } catch (e) {
      console.log(e);
    }
  }
  async function table2() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        "http://13.232.52.101/api/country/getallcountry",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setcountrylist(actualData.Country);
    } catch (e) {
      console.log(e);
    }
  }
  async function table3() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        "http://13.232.52.101/api/faqforcountry/getallfaqforcountry",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setMapList(actualData.FAQ_For_Country);
    } catch (e) {
      console.log(e);
    }
  }
  // const getAllName = () => {
  //   return mapList
  //     .map((ml) => {
  //       const vsInfo = visaList.find((pl) => pl.id === ml.FAQ_id);
  //       const fromcountryinfo = countrylist.find(
  //         (cl) => cl.id === ml.from_country_id
  //       );
  //       const tocountryinfo = countrylist.find(
  //         (cl) => cl.id === ml.to_country_id
  //       );

  //       if (fromcountryinfo && tocountryinfo && vsInfo) {
  //         return {
  //           //visaid
  //           from_country_id: fromcountryinfo.id,
  //           from_country_name: fromcountryinfo.country_name,
  //           to_country_name: tocountryinfo.country_name,
  //           to_country_id: tocountryinfo.id,
  //           question: vsInfo.Question,
  //           faq_id: vsInfo.id,
  //           map_id: ml.id,
  //         };
  //       }
  //       return null; // or any other value you prefer for undefined cases
  //     })
  //     .filter(Boolean); // filter out undefined values from the array
  // };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const tableData = await table();
        const countryInfoData = await table2();
        const map_info = await table3();
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors if necessary
      }
    };
    console.log(state);
    fetchData();
  }, []);
  React.useEffect(() => {
    // const partnerfullinfo = getAllName();
    // setMainList(partnerfullinfo);
    // console.log("combined array");
    // console.log(partnerfullinfo);
  }, [mapList]);

  const handleSaveVisaCategory = () => {
    axios
      .post(
        "http://13.232.52.101/api/faqforcountry/createfaqforcountry",
        {
          from_country_id: state.from_country_id,
          to_country_id: state.to_country_id,
          FAQ_id: state.faq_id,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => table3())
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      faq_question: "",
      faq_id: "",
      from_country_name: "",
      from_country_id: "",
      to_country_name: "",
      to_country_id: "",
      isUpdate: false,
    });
  };
  const handleVisaCategoryDelete = (idx) => {
    axios
      .post(
        "http://13.232.52.101/api/faqforcountry/deletefaqforcountry",
        {
          id: idx,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => table3())
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
  };
  const handleUpdateVisaCategory = (idx) => {
    axios
      .post(
        "http://13.232.52.101/api/faqforcountry/updatefaqforcountry",
        {
          id: idx,
          from_country_id: state.from_country_id,
          to_country_id: state.to_country_id,
          FAQ_id: state.faq_id,
        },
        {
          headers: {
            /* Your headers here */
            "Content-Type": "application/json", // Example header
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        }
      )
      .then((r) => {
        table3();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error here
      });
    setState({
      faq_question: "",
      faq_id: "",
      from_country_name: "",
      from_country_id: "",
      to_country_name: "",
      to_country_id: "",
      isUpdate: false,
    });
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            <Typography variant="h3" color={"primary"}>
              FAQ for Country
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <p className={classes.title}>Frequently Asked Question</p>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              {/* <Grid item xs={5}>
                <CustomInputField
                  type="file"
                  fullWidth
                  label="Logo image"
                  onChange={(e) => {
                    const imageRef = ref(
                      storage,
                      `/thumbnail+${Math.random()}`
                    );
                    uploadBytes(imageRef, e.target.files[0]).then(() => {
                      getDownloadURL(imageRef)
                        .then((url) => {
                          localStorage.setItem("logo", url);
                          setState({
                            ...state,
                            logo: localStorage.getItem("logo"),
                          });
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    });
                  }}
                />
              </Grid> */}
              {/* <Grid item xs={12} md={2}>
                {state.logo && (
                  <>
                    <p>logo</p>
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={state.logo}
                      style={{
                        margin: "auto",
                      }}
                    />
                  </>
                )}
              </Grid> */}

              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={countrylist.map((item) => item.country_name)} // Set options from entry type names
                  value={state.from_country_name}
                  label="From Country names"
                  fullWidth
                  changeCallBack={(e, v) => {
                    const selectedEntryType = countrylist.find(
                      (item) => item.country_name === v
                    );
                    setState((prevState) => ({
                      ...prevState,
                      from_country_name: v,
                      from_country_id: selectedEntryType
                        ? selectedEntryType.id
                        : "",
                    }));
                  }}
                  // Handle onChange event
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={countrylist.map((item) => item.country_name)} // Set options from entry type names
                  value={state.to_country_name}
                  label="To Country names"
                  fullWidth
                  changeCallBack={(e, v) => {
                    const selectedEntryType = countrylist.find(
                      (item) => item.country_name === v
                    );
                    setState((prevState) => ({
                      ...prevState,
                      to_country_name: v,
                      to_country_id: selectedEntryType
                        ? selectedEntryType.id
                        : "",
                    }));
                  }}
                  // Handle onChange event
                />
              </Grid>

              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={visaList.map((item) => item.Question)} // Set options from entry type names
                  value={state.question}
                  label="Faq for country"
                  fullWidth
                  changeCallBack={(e, v) => {
                    const selectedEntryType = visaList.find(
                      (item) => item.Question === v
                    );

                    setState((prevState) => ({
                      ...prevState,
                      faq_question: v,
                      faq_id: selectedEntryType ? selectedEntryType.id : "",
                    }));
                    console.log(state);
                  }}
                  // Handle onChange event
                />
              </Grid>

              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      handleUpdateVisaCategory(state.id);
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      handleSaveVisaCategory();
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
          <p className={classes.title}>FAQ for Country Lists</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={[
                "Sl No.",
                "From Country",
                "To Country",
                "FAQ Question",
                "Action",
              ]}
              rows={[
                ...mapList
                  // .filter(
                  //   (l) =>
                  //     l.from_country_name
                  //       .toLowerCase()
                  //       .includes(state.searchText.toLowerCase()) ||
                  //     l.to_country_name
                  //       .toLowerCase()
                  //       .includes(state.searchText.toLowerCase()) ||
                  // l.Question.toLowerCase().includes(
                  //   state.searchText.toLowerCase()
                  // ) ||
                  //     state.searchText.toLowerCase() === undefined
                  // )
                  .map((pl, idx) => [
                    idx + 1,
                    pl.from_country_name,
                    pl.to_country_name,
                    pl.Question,
                    <span className={classes.actionBtn}>
                      <CustomButton
                        variant="contained"
                        color="error"
                        onClick={(e) => handleVisaCategoryDelete(pl.id)}
                        icon={<FaTimes />}
                      />
                      <CustomButton
                        variant="contained"
                        color="warning"
                        onClick={(e) =>
                          setState({
                            //visa_id: pl.visa_id,
                            from_country_name: pl.from_country_name,
                            to_country_name: pl.to_country_name,
                            from_country_id: pl.from_country_id,
                            to_country_id: pl.to_country_id,
                            faq_question: pl.Question,
                            faq_id: pl.faq_id,
                            isUpdate: true,
                            id: pl.id,
                          })
                        }
                        icon={<FaEdit />}
                      />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
