import React, { useState } from "react";
import {
  Avatar,
  Divider,
  Typography,
  Grid,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import {
  FaEdit,
  FaEye,
  FaTimes,
  FaUserFriends,
  FaFilePdf,
  FaFileExcel,
  FaPrint,
} from "react-icons/fa";
import { BsListCheck } from "react-icons/bs";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import axios from "axios";
import { useNavigate } from "react-router";
import CountryTable from "../../components/CountryTable/Index";
import CustomMenuButton from "../../components/CustomMenuButton/Index";
import { MdLogout } from "react-icons/md";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";

export default function Rejected_application() {
  const classes = countryListStyles();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("applicantName");

  const handleSearchCriteriaChange = (event) => {
    setSearchCriteria(event.target.value);
  };

  const applicantsData = [
    {
      applicantName: "Subham Nayak",
      villageName: "Khurda",
      blockName: "Banapur",
      district: "Khurda",
      date: "2024-06-01",
      status: "Rejected",
    },
    {
      applicantName: "Anjali Behera",
      villageName: "Jajpur",
      blockName: "Dasarathpur",
      district: "Jajpur",
      date: "2024-06-02",
      status: "Rejected",
    },
    {
      applicantName: "Bikash Dash",
      villageName: "Bhubaneswar",
      blockName: "Balianta",
      district: "Khurda",
      date: "2024-06-03",
      status: "Rejected",
    },
    {
      applicantName: "Lipika Patnaik",
      villageName: "Cuttack",
      blockName: "Niali",
      district: "Cuttack",
      date: "2024-06-04",
      status: "Rejected",
    },
    {
      applicantName: "Rakesh Sahu",
      villageName: "Sambalpur",
      blockName: "Jujumura",
      district: "Sambalpur",
      date: "2024-06-05",
      status: "Rejected",
    },
    {
      applicantName: "Priti Mohanty",
      villageName: "Balasore",
      blockName: "Nilgiri",
      district: "Balasore",
      date: "2024-06-06",
      status: "Rejected",
    },
    {
      applicantName: "Anil Mishra",
      villageName: "Puri",
      blockName: "Kakatpur",
      district: "Puri",
      date: "2024-06-07",
      status: "Rejected",
    },
    {
      applicantName: "Sasmita Rout",
      villageName: "Rourkela",
      blockName: "Bisra",
      district: "Sundargarh",
      date: "2024-06-08",
      status: "Rejected",
    },
    {
      applicantName: "Manas Swain",
      villageName: "Bargarh",
      blockName: "Attabira",
      district: "Bargarh",
      date: "2024-06-09",
      status: "Rejected",
    },
    {
      applicantName: "Ipsita Jena",
      villageName: "Angul",
      blockName: "Chhendipada",
      district: "Angul",
      date: "2024-06-10",
      status: "Rejected",
    },
  ];

  const filteredData = applicantsData.filter((applicant) => {
    const searchTextLower = searchText.toLowerCase();
    const fieldToSearch = applicant[searchCriteria].toLowerCase();
    return fieldToSearch.includes(searchTextLower);
  });

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  const handleExport = (type) => {
    const links = {
      pdf: "https://cdnbbsr.s3waas.gov.in/s36832a7b24bc06775d02b7406880b93fc/uploads/2024/06/2024062923.pdf",
      excel:
        "https://docs.google.com/spreadsheets/d/1YIlq8dfKs5vPf0jGc8Vt2_dtqGGVmdEBUcf_s_jveno/edit?gid=0#gid=0",
    };
    const link = document.createElement("a");
    link.href = links[type];
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePrint = () => {
    window.print();
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Rejected Application List
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>

        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Grid
            container
            spacing={2}
            // style={{ display: "center", alignItems: "center" }}
          >
            <Grid item xs={12} sm={4}>
              <CustomInputAndSelectField
                options={[
                  "Alavar",
                  "Balikuda",
                  "Kalio",
                  "Machhagaon",
                  "Thailo",
                ]}
                // value={state.is_comingsoon}
                // changeCallBack={(e, v) => {
                //   console.log(v);
                //   if (v == "Yes") {
                //     handleStateChange("is_comingsoon", "True");
                //   } else {
                //     handleStateChange("is_comingsoon", "False");
                //   }
                // }}
                label="GP"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomInputField
                label="Search..."
                fullWidth
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="searchField"
              />
            </Grid>
            <Grid item sm={4}>
              <div
                style={{
                  display: "flex",
                  gap: "14px",
                  marginTop: "48px",
                  marginLeft: "40px",
                }}
              >
                <FaFilePdf
                  size={24}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleExport("pdf")}
                />
                <FaFileExcel
                  size={24}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleExport("excel")}
                />
                <FaPrint
                  size={24}
                  style={{ cursor: "pointer" }}
                  onClick={() => handlePrint()}
                />
              </div>
            </Grid>
          </Grid>
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "Applicant Name",
              "Village Name",
              "Block Name",
              "District",
              "Date",
              "Status",
              "Action",
            ]}
            rows={filteredData.map((applicant, idx) => [
              idx + 1,
              applicant.applicantName,
              applicant.villageName,
              applicant.blockName,
              applicant.district,
              applicant.date,
              applicant.status,
              <span className={classes.actionBtn}>
                <CustomMenuButton menuinfo={applicant} />
              </span>,
            ])}
          />
        </div>
      </div>
    </div>
  );
}
