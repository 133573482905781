import { Avatar, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
// import { RiVisaFill } from "react-icons/ri";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
// import CustomPill from "../../components/CustomPill/Index";
// import CustomTable from "../../components/CustomTable/Index";
// import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import offerStyles from "../../styles/pages/VisaCategory/index.style";
import axios from "axios";
import CustomTimeTable from "../../components/CustomTimeTable/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import { storage } from "../../firebase_config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
export default function Docs_required() {
  const classes = offerStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { visaId } = location.state || {};
  const token = localStorage.getItem("tkn");
  const formData = new FormData();
  const [state, setState] = React.useState({
    visa_name: "",
    visa_id: "",
    docs_icon: "",
    docs_title: "",
    isUpdate: false,
    searchText: "",
    id: null,
  });
  const [visaList, setVisaList] = React.useState([]);
  const [visaName, setVisaName] = React.useState("");
  const [mainlist, setMainList] = React.useState([]);
  const authToken = localStorage.getItem("tkn");

  async function table() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { visa_id: visaId };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        "http://13.232.52.101/api/visa/documentrequireddetailsbyvisaid",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setVisaList(actualData.Document_details);
      setState((prevState) => ({
        ...prevState,
        visa_id: visaId,
        visa_name: actualData.Visa_name,
      }));
      setVisaName(actualData.Visa_name);
    } catch (e) {
      console.log(e);
    }
  }

  React.useEffect(() => {
    table();
  }, []);

  const handleSaveVisaCategory = () => {
    axios
      .post(
        "http://13.232.52.101/api/documentrequired/createdocumentrequired",
        {
          visa_id: state.visa_id,
          document_icon: state.docs_icon,
          document_name: state.docs_title,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((r) => {
        table();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    setState((prevState) => ({
      ...prevState,
      visa_name: visaName,
      visa_id: state.visa_id,
      docs_icon: "",
      docs_title: "",
      isUpdate: false,
      id: null,
    }));
  };

  const handleVisaCategoryDelete = (idx) => {
    axios
      .post(
        "http://13.232.52.101/api/documentrequired/deletedocumentrequired",
        {
          id: idx,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((r) => {
        table();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleUpdateVisaCategory = (idx) => {
    axios
      .post(
        "http://13.232.52.101/api/documentrequired/updatedocumentrequired",
        {
          id: idx,
          visa_id: state.visa_id,
          document_icon: state.docs_icon,
          document_name: state.docs_title,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((r) => {
        if (r.status === 200) {
          table();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    setState((prevState) => ({
      ...prevState,
      visa_name: visaName,
      visa_id: state.visa_id,
      docs_icon: "",
      docs_title: "",
      isUpdate: false,
      id: null,
    }));
  };

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Documents Required
            </Typography>
          </div>

          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <p className={classes.title}>Documents</p>
          <Paper className={classes.paper}>
            <Grid container spacing={3} className="content" alignItems={"end"}>
              <Grid item xs={5}>
                <CustomInputAndSelectField
                  options={visaList.map((item) => item.visa_name)}
                  value={state.visa_name}
                  label="Visa names"
                  fullWidth
                  changeCallBack={(e, v) => {
                    const selectedEntryType = visaList.find(
                      (item) => item.visa_name === v
                    );
                    setState((prevState) => ({
                      ...prevState,
                      visa_name: v,
                      visa_id: selectedEntryType ? selectedEntryType.id : "",
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <CustomInputField
                  type="file"
                  fullWidth
                  label="Document icon"
                  onChange={(e) => {
                    const imageRef = ref(
                      storage,
                      `/thumbnail+${Math.random()}`
                    );
                    uploadBytes(imageRef, e.target.files[0]).then(() => {
                      getDownloadURL(imageRef)
                        .then((url) => {
                          setState((prevState) => ({
                            ...prevState,
                            docs_icon: url,
                          }));
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                {state.docs_icon && (
                  <>
                    <p>Icon</p>
                    <Avatar
                      variant="rounded"
                      alt="VISA"
                      src={state.docs_icon}
                      style={{
                        margin: "auto",
                      }}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <CustomInputField
                  label="Document name"
                  fullWidth
                  value={state.docs_title}
                  name="name"
                  onChange={(e) => {
                    setState((prevState) => ({
                      ...prevState,
                      docs_title: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={2} alignItems="end">
                {state.isUpdate ? (
                  <CustomButton
                    text={"Update"}
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={(e) => {
                      handleUpdateVisaCategory(state.id);
                    }}
                  />
                ) : (
                  <CustomButton
                    text={"Create"}
                    variant="contained"
                    fullWidth
                    onClick={(e) => {
                      handleSaveVisaCategory();
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
          <p className={classes.title}>Documents Required Lists</p>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  searchText: e.target.value,
                }))
              }
              className="searchField"
            />
            <CustomTimeTable
              fullWidth
              cols={["Sl No.", "Visa", "Icon", "Document Name", "Action"]}
              rows={[
                ...visaList.map((pl, idx) => [
                  idx + 1,
                  state.visa_name,
                  <Avatar
                    variant="rounded"
                    alt="VISA"
                    src={pl.document_icon}
                    style={{
                      margin: "auto",
                    }}
                  />,
                  pl.document_name,
                  <span className={classes.actionBtn}>
                    <CustomButton
                      variant="contained"
                      color="error"
                      onClick={(e) => handleVisaCategoryDelete(pl.id)}
                      icon={<FaTimes />}
                    />
                    <CustomButton
                      variant="contained"
                      color="warning"
                      onClick={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          docs_icon: pl.document_icon,
                          docs_title: pl.document_name,
                          isUpdate: true,
                          id: pl.id,
                        }))
                      }
                      icon={<FaEdit />}
                    />
                  </span>,
                ]),
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
