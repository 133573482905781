const apiGateWayUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000/api/v1"
    : process.env.REACT_APP_BACKEND_URL + "/api/v1";

const endPoints = {
  apiGateWay: apiGateWayUrl,
  isAuth: `${apiGateWayUrl}/isAuth`,
  signIn: `${apiGateWayUrl}/signIn`,
  signUp: `${apiGateWayUrl}/signUp`,
  createCountry: `${apiGateWayUrl}/createCountry`,
  updateCountry: `${apiGateWayUrl}/updateCountry`,
  getCountryList: `${apiGateWayUrl}/getCountryList`,
  deleteCountry: `${apiGateWayUrl}/deleteCountry`,
  getVisaCategory: `${apiGateWayUrl}/getVisaCategory`,
  createVisaCategory: `${apiGateWayUrl}/createVisaCategory`,
  updateVisaCategory: `${apiGateWayUrl}/updateVisaCategory`,
  deleteVisaCategory: `${apiGateWayUrl}/deleteVisaCategory`,
  createVisaType: `${apiGateWayUrl}/createVisaType`,
  getVisaType: `${apiGateWayUrl}/getVisaType`,
  updateVisaType: `${apiGateWayUrl}/updateVisaType`,
  deleteVisaType: `${apiGateWayUrl}/deleteVisaType`,
  createVisaPricing: `${apiGateWayUrl}/registerAdminVisaPricing`,
  getVisaPricing: `${apiGateWayUrl}/getAdminVisaPricing`,
  updateVisaPricing: `${apiGateWayUrl}/updateAdminVisaPricing`,
  deleteVisaPricing: `${apiGateWayUrl}/deleteAdminVisaPricing`,
  getCalculatorData: `${apiGateWayUrl}/getVisaProcessing`,
  createCalculatorData: `${apiGateWayUrl}/createVisaProcessing`,
  updateCalculatorData: `${apiGateWayUrl}/updateVisaProcessing`,
  deleteCalculatorData: `${apiGateWayUrl}/deleteVisaProcessing`,
  getVisaApplications: `${apiGateWayUrl}/getVisaApplications`,
  getBasicInfo: `${apiGateWayUrl}/getBasicInfo`,
  getTravelingDate: `${apiGateWayUrl}/getTravelingDate`,
  getApplicantInfo: `${apiGateWayUrl}/getApplicantInfo`,
  updateVisaApplication: `${apiGateWayUrl}/updateVisaApplication`,
  getOffers: `${apiGateWayUrl}/getOffers`,
  registerOffer: `${apiGateWayUrl}/registerOffer`,
  sendEmail: `${apiGateWayUrl}/sendMail`,
  getAllCoupons: `${apiGateWayUrl}/getAllCoupons`,
  createCoupon: `${apiGateWayUrl}/createCoupon`,
  updateCoupon: `${apiGateWayUrl}/updateCoupon`,
  deleteCoupon: `${apiGateWayUrl}/deleteCoupon`,
  getPayOuts: `${apiGateWayUrl}/findWalletHistoryAdmin`,
  getPartners: `${apiGateWayUrl}/getPartners`,
  loginPartner: `${apiGateWayUrl}/partnerLogin`,
  getDashboardDetails: `${apiGateWayUrl}/getAdminDashboardData`,
  getRevenueDetails: `${apiGateWayUrl}/getRevenueDetails`,
};

export default endPoints;
