import { Avatar, Divider, Typography, Grid, Button } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { BsListCheck } from "react-icons/bs";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import endPoints from "../../config/endPoint";
import axios from "axios";
import { useNavigate } from "react-router";
import CountryTable from "../../components/CountryTable/Index";
import { EditorState, convertFromRaw } from "draft-js";
export default function Testimonial_list() {
  const classes = countryListStyles();
  const [list, setList] = React.useState([]);
  const [visalist, setVisaList] = React.useState([]);
  const [mainlist, setMainList] = React.useState([]);
  const [countrylist, setcountrylist] = React.useState([]);
  const [testimonialMap, settestimonialMap] = React.useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const [searchText, setSearchText] = React.useState("");
  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        "http://13.232.52.101/api/customertestimonial/getallcustomertestimonial",
        requestOptions
      );
      const actualData = await res.json();

      const data = actualData.Customer_Testimonials.filter(
        (review) => review.visa_id === null
      );
      console.log(data);
      setList(data);
    } catch (err) {
      console.log(err);
    }
  };

  async function country_info() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStora
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        "http://13.232.52.101/api/country/getallcountry",
        requestOptions
      );
      const response = await res.json();
      setcountrylist(response.Country);
      console.log(response.Country);
    } catch (err) {
      console.log(err);
    }
  }
  async function testimonial_map() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        "http://13.232.52.101/api/customertestimonialmap/getallcustomertestimonialmap",
        requestOptions
      );
      const response = await res.json();
      settestimonialMap(response.FAQ_For_Customer);
      console.log(response.FAQ_For_Customer);
    } catch (err) {
      console.log(err);
    }
  }
  const getVisaInfoWithCountry = () => {
    return testimonialMap
      .map((ts) => {
        const visaInfo = list.find((info) => info.id === ts.testimonial_id);

        const countryfrom = countrylist.find(
          (ct) => ct.id === ts.from_country_id
        );
        const countryto = countrylist.find((ct) => ct.id === ts.to_country_id);
        if (visaInfo && countryfrom && countryto) {
          return {
            visaInfo,
            from_country_id: ts.from_country_id,
            to_country_id: ts.to_country_id,
            from_country: countryfrom.country_name,
            to_country: countryto.country_name,
            map_id: ts.id,
            testimonial_id: ts.testimonial_id,
          };
        }

        return null; // or any other value you prefer for undefined cases
      })
      .filter(Boolean); // filter out undefined values from the array
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const tableData = await table();
        const entryInfoData = await country_info();
        const testimonialMapData = await testimonial_map();
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors if necessary
      }
    };
    fetchData(); // Call the fetchData function
  }, []);
  React.useEffect(() => {
    const visaInfoWithTypeArray = getVisaInfoWithCountry();
    setMainList(visaInfoWithTypeArray);
    console.log("combined array");
    console.log(visaInfoWithTypeArray);
  }, [list, testimonialMap, countrylist]);
  const handleDelete = async (idx, map) => {
    console.log(map);
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        "http://13.232.52.101/api/customertestimonial/deletecustomertestimonial",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        axios
          .post(
            "http://13.232.52.101/api/customertestimonialmap/deletecustomertestimonialmap",
            {
              id: map,
            },
            {
              headers: {
                /* Your headers here */
                "Content-Type": "application/json", // Example header
                Authorization: `Bearer ${token}`, // Example authorization header
              },
            }
          )
          .then((r) => {
            const fetchData = async () => {
              try {
                await Promise.all([table(), country_info(), testimonial_map()]); // Wait for all API calls to finish
              } catch (error) {
                console.error("Error fetching data:", error);
                // Handle errors if necessary
              }
            };
            fetchData();
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            // Handle error her
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Customer Testimonial List
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography variant="h4" color={"primary"}>
              <FaUserFriends /> Log Out
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) => {
                navigate("/testimonial");
              }}
            >
              Create customer testimonial
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "Customer Name",
              "Customer Image",
              "Description",
              "Title",
              "Location",
              "Date",

              "Action",
            ]}
            rows={[
              ...mainlist
                // .filter(
                //   (l) =>
                //     l.visaInfo.customer_name
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     l.visaInfo.customer_location
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     l.visaInfo.date
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     l.visaInfo.Title.toLowerCase().includes(
                //       searchText.toLowerCase()
                //     ) ||
                //     l.from_country
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     l.to_country
                //       .toLowerCase()
                //       .includes(searchText.toLowerCase()) ||
                //     searchText.toLowerCase() === undefined
                // )
                .map((vs, idx) => [
                  idx + 1,
                  vs.visaInfo.customer_name,
                  <Avatar
                    variant="rounded"
                    alt="VISA"
                    src={vs.visaInfo.customer_image}
                    style={{
                      margin: "auto",
                    }}
                  />,
                  JSON.parse(vs.visaInfo.description).blocks[0].text.length > 25
                    ? JSON.parse(vs.visaInfo.description).blocks[0].text.slice(
                        0,
                        25
                      ) + "...."
                    : JSON.parse(vs.visaInfo.description).blocks[0].text,
                  vs.visaInfo.Title,
                  vs.visaInfo.customer_location,
                  vs.visaInfo.date,

                  <span className={classes.actionBtn}>
                    <CustomButton
                      variant="contained"
                      color="error"
                      onClick={(e) => handleDelete(vs.visaInfo.id, vs.map_id)}
                      icon={<FaTimes />}
                    />
                    <CustomButton
                      variant="contained"
                      color="warning"
                      onClick={(e) => {
                        localStorage.setItem(
                          "updateTestimonial",
                          JSON.stringify(vs.visaInfo)
                        );
                        localStorage.setItem(
                          "updateTestMap",
                          JSON.stringify(vs)
                        );
                        // localStorage.setItem("testimonialMapId", vs.map_id);
                        // localStorage.setItem("entry_id", vs.entry_id);
                        navigate("/testimonial");
                      }}
                      icon={<FaEdit />}
                    />
                  </span>,
                ]),
            ]}
          />
        </div>
      </div>
    </div>
  );
}
