import { Divider, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { FaEdit, FaTimes } from "react-icons/fa";
import CustomButton from "../../components/CustomButton/Index";
import CustomDatePicker from "../../components/CustomDateField/Index";
import CustomInputAndSelectField from "../../components/CustomInputAndSelectField/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomPill from "../../components/CustomPill/Index";
import CustomTable from "../../components/CustomTable/Index";
import endPoints from "../../config/endPoint";
import DashboardLayout from "../../layouts";
import couponStyles from "../../styles/pages/Dashboard/coupon.style";

export default function PayoutPage() {
  const classes = couponStyles();
  const token = localStorage.getItem("token");
  const [state, setState] = React.useState({
    coupon: "",
    expiryDate: null,
    maxAmount: "",
    waivedAmount: "",
    couponType: "",
    isUpdate: false,
    searchText: "",
  });
  const [payouts, setPayouts] = React.useState([]);
  React.useEffect(() => {
    axios.get(endPoints.getPayOuts).then((r) => setPayouts(r.data.results));
  }, []);

  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div className={classes.contentHead}>
          <Typography variant="h3" color={"primary"}>
            Payout
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <Paper className={classes.paper}>
            <CustomInputField
              label="Search..."
              fullWidth
              value={state.searchText}
              onChange={(e) =>
                setState({
                  ...state,
                  searchText: e.target.value,
                })
              }
              className="searchField"
            />
            <CustomTable
              fullWidth
              cols={[
                "Sl No.",
                "Partner ID",
                "Source",
                "Amount",
                "Status",
                "Invoice Number",
                "Action",
              ]}
              rows={[
                ...payouts
                  .sort()
                  .filter(
                    (l) =>
                      l.partner_id.includes(state.searchText) ||
                      state.searchText === undefined
                  )
                  .map((vs, idx) => [
                    idx + 1,
                    vs.partner_id,
                    vs.source,
                    vs.amount,
                    vs.status === "processing" ? (
                      <CustomPill value={vs.status} color="warning" />
                    ) : vs.status === "rejected" ? (
                      <CustomPill value={vs.status} color="error" />
                    ) : (
                      <CustomPill value={vs.status} color="success" />
                    ),
                    vs.invoiceNo,
                    <span className={classes.actionBtn}>
                      <CustomButton text={"Pay"} variant="contained" />
                    </span>,
                  ]),
              ]}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
}
