import { Avatar, Divider, Typography, Grid, Button } from "@mui/material";
import React from "react";
import { FaEdit, FaEye, FaTimes, FaUserFriends } from "react-icons/fa";
import { BsListCheck } from "react-icons/bs";
import CustomButton from "../../components/CustomButton/Index";
import CustomInputField from "../../components/CustomInputField/Index";
import CustomTable from "../../components/CustomTable/Index";
import DashboardLayout from "../../layouts";
import countryListStyles from "../../styles/pages/CountryList/index.style";
import endPoints from "../../config/endPoint";
import axios from "axios";
import { useNavigate } from "react-router";
import CountryTable from "../../components/CountryTable/Index";
import { EditorState, convertFromRaw } from "draft-js";
import { MdLogout } from "react-icons/md";
export default function Question_list() {
  const classes = countryListStyles();
  const [list, setList] = React.useState([]);
  const [mainlist, setMainList] = React.useState([]);
  const [visalist, setvisalist] = React.useState([]);
  const [answer_map, setanswer_map] = React.useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");
  const [searchText, setSearchText] = React.useState("");
  const [typelist, setTypelist] = React.useState([
    "text input",
    "Number",
    "Upload",
    "Date Picker",
    "Toggle",
    "Text Area",
    "Drop Down",
    "Check box",
    "Radio",
  ]);
  const table = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        "http://13.232.52.101/api/question/getallquestion",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      setList(actualData.Question);
    } catch (err) {
      console.log(err);
    }
  };
  async function visa_info() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStora
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        "http://13.232.52.101/api/visainformation/getallvisainformation",
        requestOptions
      );
      const response = await res.json();
      setvisalist(response.Visa_Information);
      console.log(response.Visa_Information);
    } catch (err) {
      console.log(err);
    }
  }
  async function answer_info() {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStora
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }

      const requestOptions = {
        method: "GET",
        headers: loginHeaders,
      };
      const res = await fetch(
        "http://13.232.52.101/api/answer/getallanswer",
        requestOptions
      );
      const response = await res.json();
      setanswer_map(response.Answers);
      console.log(response.Answers);
    } catch (err) {
      console.log(err);
    }
  }

  const getVisaInfoWithType = () => {
    if (visalist || answer_map) {
      return list
        .map((ls) => {
          const answerInfo = answer_map.find(
            (ans) => ans.question_id === ls.id
          );

          if (answerInfo) {
            return {
              visa_name: ls.visa_name,
              question_name: ls.question_name,
              question_type: ls.question_type,
              choice: answerInfo ? answerInfo.choice : [],
              visa_id: ls.visa_id,
              map_id: ls.id,
              required: ls.required_or_not,
            };
          }

          return null; // or any other value you prefer for undefined cases
        })
        .filter(Boolean); // filter out undefined values from the array
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const tableData = await table();
        const entryInfoData = await visa_info();
        const answer_data = await answer_info();
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors if necessary
      }
    };
    fetchData(); // Call the fetchData function
  }, []);
  React.useEffect(() => {
    const visaInfoWithTypeArray = getVisaInfoWithType();

    setMainList(visaInfoWithTypeArray);
    console.log("combined array");
    console.log(visaInfoWithTypeArray);
  }, [list, answer_map, visalist]);
  const handleDelete = async (idx) => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");

      // Assuming you have an authorization token stored in localStorage
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: idx };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        "http://13.232.52.101/api/question/deletequestion",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      // setVisaList(actualData.Country);
      if (actualData.status == 200) {
        table();
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={classes.container}>
      <DashboardLayout />
      <div className={classes.contentSection}>
        <div
          className={classes.contentHead}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" color={"primary"}>
              Question List
            </Typography>
          </div>
          <div
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <Typography
              variant="h4"
              color="primary"
              style={{ display: "flex", alignItems: "center" }}
            >
              Log out
              <MdLogout size={30} style={{ marginLeft: "8px" }} />
            </Typography>
          </div>
        </div>
        <Grid container spacing={4} className={classes.contentMain}>
          <Grid item xs={3}>
            <Button
              variant="contained"
              fullWidth
              onClick={(e) => {
                navigate("/question");
              }}
            >
              Create Question
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <div className={classes.contentMain}>
          <CustomInputField
            label="Search..."
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="searchField"
          />
          <CountryTable
            fullWidth
            cols={[
              "Sl No.",
              "Visa Name",
              "Question",
              "Question Type",
              "Choice",
              "Required",
              "Action",
            ]}
            rows={[
              ...mainlist
                .filter(
                  (l) =>
                    l.visa_name
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    l.question_name
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    searchText.toLowerCase() === undefined
                )
                .map((vs, idx) => [
                  idx + 1,
                  vs.visa_name,
                  vs.question_name,
                  typelist[vs.question_type - 1],
                  vs.choice ? vs.choice.join(",") : "",
                  vs.required == true ? "Yes" : "No",
                  <span className={classes.actionBtn}>
                    <CustomButton
                      variant="contained"
                      color="error"
                      onClick={(e) => handleDelete(vs.map_id)}
                      icon={<FaTimes />}
                    />
                    <CustomButton
                      variant="contained"
                      color="warning"
                      onClick={(e) => {
                        localStorage.setItem(
                          "updateQuestion",
                          JSON.stringify(vs)
                        );

                        navigate("/question");
                      }}
                      icon={<FaEdit />}
                    />
                  </span>,
                ]),
            ]}
          />
        </div>
      </div>
    </div>
  );
}
