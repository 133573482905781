import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router";
import { FaEllipsisV } from "react-icons/fa";

const MenuButton = ({ menuinfo, fetchData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("tkn");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleView = () => {
    const data = JSON.stringify(menuinfo);
    localStorage.setItem("updateVisa", JSON.stringify(menuinfo.visaInfo));
    localStorage.setItem("updateVisatype", menuinfo.entryTypeNames);
    localStorage.setItem("entry_id", menuinfo.entry_ids);
    navigate("/information_form");
    handleClose();
  };

  const handleDelete = async () => {
    try {
      const loginHeaders = new Headers();
      loginHeaders.append("Content-Type", "application/json");
      const authToken = localStorage.getItem("tkn");
      if (authToken) {
        loginHeaders.append("Authorization", `Bearer ${authToken}`);
      }
      const data = { id: menuinfo.visaInfo.id };
      const requestOptions = {
        method: "POST",
        headers: loginHeaders,
        body: JSON.stringify(data),
      };
      const res = await fetch(
        "http://13.232.52.101/api/visainformation/deletevisainformation",
        requestOptions
      );
      const actualData = await res.json();
      console.log(actualData);
      if (actualData.status === 200) {
        menuinfo.map_ids.forEach((mapId) => {
          axios
            .post(
              "http://13.232.52.101/api/visaentrytypemap/deletevisaentrytypemap",
              {
                id: mapId,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((r) => {})
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        });
      }
      await fetchData(); // Call the fetchData function from the parent component
    } catch (err) {
      console.log(err);
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setStatusMenuAnchorEl(null);
  };

  const handleUpdateStatusClick = (event) => {
    setStatusMenuAnchorEl(statusMenuAnchorEl ? null : event.currentTarget);
  };

  const handleStatusMenuClose = () => {
    setStatusMenuAnchorEl(null);
  };
  const handleFaqClick = () => {
    navigate("/faq_visa", { state: { visaId: menuinfo.visaInfo.id } });
    handleClose();
  };
  const handlePartnerClick = () => {
    navigate("/partners", { state: { visaId: menuinfo.visaInfo.id } });
    handleClose();
  };
  const handleCustomerClick = () => {
    navigate("/testimonial", { state: { visaId: menuinfo.visaInfo.id } });
    handleClose();
  };
  const handleDocsClick = () => {
    navigate("/docs_required", { state: { visaId: menuinfo.visaInfo.id } });
    handleClose();
  };
  const handleImmigrationClick = () => {
    navigate("/immigration_form", { state: { visaId: menuinfo.visaInfo.id } });
    handleClose();
  };

  return (
    <>
      <FaEllipsisV
        size={20}
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleView}>Update</MenuItem>
        <MenuItem onClick={handleDelete}>Remove</MenuItem>
        <MenuItem onClick={handleFaqClick}>Faq section</MenuItem>
        <MenuItem onClick={handlePartnerClick}>
          Partner we work with section
        </MenuItem>
        <MenuItem onClick={handleCustomerClick}>
          Customer Testimonial section
        </MenuItem>
        <MenuItem onClick={handleDocsClick}>Document section</MenuItem>
        <MenuItem onClick={handleImmigrationClick}>
          Immigration officier section
        </MenuItem>
      </Menu>
    </>
  );
};

export default MenuButton;
