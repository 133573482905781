import * as React from "react";
import Chip from "@mui/material/Chip";

export default function CustomPill({ value, handleDeleteCallback, ...props }) {
  return (
    <Chip
      size="small"
      label={value}
      onDelete={handleDeleteCallback}
      {...props}
    />
  );
}
